import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Button from '../../../components/Button';
import { theme } from '../../../styles/GlobalStyles';

/**
 * A reusable pagination component for fetching data on every
 * page change. For every page change event, this component
 * emits the number of data to skip to the callback provided by the
 * parent in order to make the API call
 * @param {Funtion} pageChangeHandler - Handler for the page change
 * @param {Number} totalRows - Total number of entries/rows
 * @param {Number} rowsPerPage - The maximum number of entries/rows to display in a single page
 *
 */

const PaginationWrapper = styled.div`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  text-align: right;
  max-width: 75rem;
  width: 100%;
  margin-top: 0.5rem;

  @media (min-width: 1100px) {
    position: unset;
    width: 100%;
  }

  @media (max-height: 1000px) {
    position: unset;
  }
`;

const Count = styled.div`
  color: #a0a3bd;
  font-size: 13px;
  letter-spacing: 0.5px;
`;

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  border: none;
  border-radius: 10px;
  color: #14142a;
  font-weight: normal;
  padding: 0;
`;

const Pagination = ({ pageChangeHandler, totalRows, rowsPerPage, currentPage, setCurrentPage }) => {
  const noOfPages = Math.ceil(totalRows / rowsPerPage);
  const pagesArr = [...new Array(noOfPages)];

  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);
  const [pageFirstRecord, setPageFirstRecord] = useState(1);
  const [pageLastRecord, setPageLastRecord] = useState(rowsPerPage);

  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = pageNo => setCurrentPage(pageNo);

  // Enable/disable previous and next buttons
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
  }, [noOfPages, currentPage]);

  // To set the starting index of the page
  useEffect(() => {
    const skipFactor = (currentPage - 1) * rowsPerPage;
    pageChangeHandler(skipFactor);
    setPageFirstRecord(skipFactor + 1);
    window.scrollTo(0, 0);
  }, [currentPage]);

  // To set the last index of the page
  useEffect(() => {
    const count = pageFirstRecord + rowsPerPage;
    setPageLastRecord(count > totalRows ? totalRows : count - 1);
  }, [pageFirstRecord, rowsPerPage, totalRows]);

  return (
    <>
      {noOfPages > 1 ? (
        <PaginationWrapper>
          <Count>
            Showing {pageFirstRecord} - {pageLastRecord} of {totalRows}
          </Count>
          <ButtonRow>
            <Button
              type="button"
              bg={!canGoBack ? '#EDEEF6' : theme.white}
              color={theme.black}
              padding="7px 15px"
              width="120px"
              cursor={!canGoBack ? 'not-allowed' : 'pointer'}
              margin="0px 4px 0px 0px"
              height="40px"
              disabled={!canGoBack}
              clickHandler={onPrevPage}>
              Prev
            </Button>
            {pagesArr.map((num, index) => (
              <Button
                key={index}
                type="button"
                bg={index + 1 === currentPage ? theme.primaryBlue : theme.white}
                color={index + 1 === currentPage ? theme.white : theme.black}
                padding="7px 15px"
                width="40px"
                margin="0px 3px"
                height="40px"
                clickHandler={() => onPageSelect(index + 1)}>
                {index + 1}
              </Button>
            ))}
            <Button
              type="button"
              bg={!canGoNext ? '#EDEEF6' : theme.white}
              color={theme.black}
              padding="7px 15px"
              width="120px"
              height="40px"
              margin="0px 0px 0px 4px"
              disabled={!canGoNext}
              cursor={!canGoNext ? 'not-allowed' : 'pointer'}
              // fontWeight="600"
              clickHandler={onNextPage}>
              Next
            </Button>
          </ButtonRow>
        </PaginationWrapper>
      ) : null}
    </>
  );
};

Pagination.propTypes = {
  pageChangeHandler: PropTypes.func.isRequired,
  totalRows: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
};

export default Pagination;
