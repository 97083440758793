import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from 'react-query';

import { JobsWrapperContext } from '../../context';
import { getSingleJob } from '../../services/jobs.services';

import { MainWrapper } from '../../styles/Flex.styled';

import JobForm from './components/JobForm';
import Preview from './components/Preview';
import FormTitle from './components/FormTitle';
import Loader from '../../components/Loader';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-top: 1rem;
  padding: 1rem 0;
  gap: 1.75rem;

  @media screen and (min-width: ${({ theme }) => theme.smallScreen}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

const CreateJob = () => {
  const jobId = useParams().jobId;

  const [editorMsg, setEditorMsg] = useState(null);

  let [isLoadingEl, singleJob, refetchFn] = ['', '', () => {}];
  if (jobId) {
    const { isLoading, data, refetch } = useQuery(['jobs', jobId], () => getSingleJob(jobId), {
      enabled: false,
    });
    [isLoadingEl, singleJob, refetchFn] = [isLoading, data, refetch];
  }

  useEffect(() => {
    refetchFn();
  }, [jobId]);

  if (isLoadingEl) {
    return <Loader />;
  } else if (jobId && !singleJob?.data) {
    return <Loader />;
  }

  const handleEditorMsg = value => setEditorMsg(value);
  return (
    <JobsWrapperContext>
      <MainWrapper>
        <FormTitle jobId={jobId} title={singleJob?.data?.jobTitle} editorMsg={editorMsg} />
        <Wrapper>
          <JobForm
            jobDetails={singleJob?.data}
            handleEditorMsg={handleEditorMsg}
            editorMsg={editorMsg}
          />
          <Preview editorMsg={editorMsg} editMode={singleJob?.data ? true : false} />
        </Wrapper>
      </MainWrapper>
    </JobsWrapperContext>
  );
};

CreateJob.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
};

export default CreateJob;
