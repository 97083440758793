import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonStyle = styled.button`
  background-color: ${({ bg, theme }) => bg || theme.primaryBlue};
  box-shadow: ${({ boxShadow }) => boxShadow || 'unset'};
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ color, theme }) => color || theme.white};
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border || 'none'};
  width: ${({ width }) => width || '150px'};
  height: ${({ height }) => height || 'auto'};
  font-weight: ${({ fontWeight }) => fontWeight || '500'};
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  margin: ${({ margin }) => margin || '0'};
  cursor: ${({ cursor, disabled }) => !disabled ? cursor || 'pointer' : 'not-allowed'};
  line-height: ${({ lineHeight }) => lineHeight || '1rem'};
`;
const Button = ({
  disabled,
  type,
  bg,
  color,
  padding,
  children,
  border,
  width,
  height,
  margin,
  clickHandler,
  fontWeight,
  cursor,
  fontSize,
  boxShadow,
}) => {
  return (
    <>
      <ButtonStyle
        type={type}
        bg={bg}
        color={color}
        padding={padding}
        border={border}
        height={height}
        width={width}
        margin={margin}
        fontWeight={fontWeight}
        cursor={cursor}
        onClick={clickHandler}
        boxShadow={boxShadow}
        fontSize={fontSize}
        disabled={disabled}>
        {' '}
        {children}
      </ButtonStyle>
    </>
  );
};

Button.defaultProps = {
  type: 'button',
};
Button.propTypes = {
  disabled: PropTypes.bool,
  type: PropTypes.string,
  color: PropTypes.string,
  bg: PropTypes.string,
  padding: PropTypes.string,
  children: PropTypes.any,
  border: PropTypes.string,
  boxShadow: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  margin: PropTypes.string,
  fontWeight: PropTypes.string,
  cursor: PropTypes.string,
  fontSize: PropTypes.string,
  clickHandler: PropTypes.func,
};

export default Button;
