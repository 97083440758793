import styled from 'styled-components';
import { theme } from './GlobalStyles';

export const Flex = styled.section`
  display: flex;
  flex-flow: ${({ direction }) => direction || 'row'};
  align-items: ${({ ai }) => ai || 'center'};
  justify-content: ${({ jc }) => jc || 'space-between'};
  gap: ${({ gap }) => gap || 'unset'};
  height: ${({ height }) => height || 'unset'};
  margin-bottom: ${({ mb }) => mb || 'unset'};
  width: ${({ width }) => width || 'auto'};
  padding: ${({ padding }) => padding || '0'};
  box-shadow: ${({ boxShadow }) => boxShadow || 'unset'};
  border-radius: ${({ borderRadius }) => borderRadius || 'unset'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'unset'};
  color: ${({ color }) => color};
  border: ${({ border }) => border};
  cursor: ${({ cursor }) => cursor};
  /* & > div {
    flex: 1;
  }*/

  /*@media (max-width: ${({ theme }) => theme.smallScreen}) {
    flex-flow: column;
    text-align: center;
  }*/
`;

export const Para = styled.p`
  font-weight: ${({ fontWeight }) => fontWeight || '500'};
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  color: ${({ color }) => color || theme.grey1};
  margin: 0;
  line-height: ${({ lineHeight }) => lineHeight || 'unset'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
  padding: ${({ padding }) => padding || 'unset'};
`;

export const HR = styled.hr`
  background-color: ${({ theme }) => theme.grey3};
  height: 1px;
  border: none;
  width: 100%;
  margin: ${({ margin }) => margin || '1rem 0'};
`;

export const MainWrapper = styled.section`
  height: 100%;
  background-color: ${({ theme }) => theme.backgroundColor};
  width: 100%;
  max-width: 75rem;
`;

export const Span = styled.span`
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  color: ${({ color }) => color || 'inherit'};
  line-height: ${({ lineHeight }) => lineHeight || 'normal'};
  position: ${({ position }) => position || 'unset'};
  left: ${({ left }) => left || 'unset'};
  top: ${({ top }) => top || 'unset'};
  cursor: ${({ cursor }) => cursor || 'unset'};
`;

export const AnchorTag = styled.a`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: ${({ height }) => height};
  width: ${({ width }) => width || 'auto'};
  border: ${({ border }) => border || 'unset'};
  border-radius: ${({ borderRadius }) => borderRadius || 'unset'};
  padding: ${({ padding }) => padding || 'unset'};
  color: ${({ color }) => color};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  font-weight: ${({ fontWeight }) => fontWeight || '500'};
  margin: ${({ margin }) => margin || 'unset'};
  cursor: ${({ cursor }) => cursor || 'unset'};
  pointer-events: ${({ pointerEvents }) => pointerEvents || 'unset'};
`;

export const Label = styled.label`
  color: ${({ color }) => color || theme.grey1};
  margin: ${({ margin }) => margin || 'unset'};
  font-size: ${({ fontSize }) => fontSize || '0.875rem'};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
`;
export const Input = styled.input`
  border-radius: 8px;
  width: 100%;
  height: 30px;
  padding: 10px;
  border: ${({ border }) => border || `1px solid ${theme.grey5}`};
  font-family: inherit;
  background-color: #fff !important;
  color: ${({ color }) => color || theme.black} !important;
  font-size: ${({ fontSize }) => fontSize || '0.875rem'};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  outline-color: ${theme.grey5};
`;
