import {
  BoldIcon,
  FontsIcon,
  ItalicIcon,
  OrderListIcon,
  UnderlineIcon,
  UnorderListIcon,
} from '../components/icons';

export const BLOCK_TYPES = [
  // { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item', icon: <UnorderListIcon /> },
  { label: 'OL', style: 'ordered-list-item', icon: <OrderListIcon /> },
  // { label: 'Code Block', style: 'code-block' },
];

export const HEADER_TYPES = [
  { label: 'H1', style: 'header-one', value: 'header-one' },
  { label: 'H2', style: 'header-two', value: 'header-two' },
  { label: 'H3', style: 'header-three', value: 'header-three' },
  { label: 'H4', style: 'header-four', value: 'header-four' },
  { label: 'H5', style: 'header-five', value: 'header-five' },
  { label: 'H6', style: 'header-six', value: 'header-six' },
  { label: 'Normal', style: 'unstyled', value: 'unstyled' },
];
// In-Line Style buttons

export const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD', icon: <BoldIcon /> },
  { label: 'Italic', style: 'ITALIC', icon: <ItalicIcon /> },
  { label: 'Underline', style: 'UNDERLINE', icon: <UnderlineIcon /> },
  { label: 'Monospace', style: 'CODE', icon: <FontsIcon /> },
  /* { label: 'left', style: 'ALIGNMENT', icon: <LeftAlignmentIcon /> },
  { label: 'right', style: 'ALIGNMENT', icon: <RightAlignmentIcon /> },
  { label: 'center', style: 'ALIGNMENT', icon: <CenterAlignmentIcon /> },*/
];

export const colorStyleMap = {
  red: {
    color: 'rgba(255, 0, 0, 1.0)',
  },
  orange: {
    color: 'rgba(255, 127, 0, 1.0)',
  },
  yellow: {
    color: 'rgba(180, 180, 0, 1.0)',
  },
  green: {
    color: 'rgba(0, 180, 0, 1.0)',
  },
  blue: {
    color: 'rgba(0, 0, 255, 1.0)',
  },
  indigo: {
    color: 'rgba(75, 0, 130, 1.0)',
  },
  violet: {
    color: 'rgba(127, 0, 255, 1.0)',
  },
};

export const SHORT_DESC_LENGTH = 200;
export const REACHOUT_MSG_LENGTH = 400;
export const LOCATION_LENGTH = 25;
export const JOBTITLE_LENGTH = 50;
export const COMPANY_LENGTH = 50;

export const fieldLengths = {
  jobTitle: JOBTITLE_LENGTH,
  location: LOCATION_LENGTH,
  companyName: COMPANY_LENGTH,
};

export const URL_VALIDATION =
  /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;

export const ALPHA_REGEX = /^[a-zA-Z\s]+$/g;
