import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex } from '../../../styles/Flex.styled';
import { theme } from '../../../styles/GlobalStyles';
import Button from '../../../components/Button';
import { ShareIcon } from '../../../components/icons';
import { useQueryClient, useMutation } from 'react-query';
import { updateJD } from '../../../services/jobs.services';
import { useJobContext } from '../../../context';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import ShareJd from '../../../components/shareJd';
import {
  COMPANY_LENGTH,
  JOBTITLE_LENGTH,
  LOCATION_LENGTH,
  SHORT_DESC_LENGTH,
} from '../../../common/constant';
// import { getObjectKeysValue } from '../../../common/helper';

const Title = styled.h1`
  font-weight: 700;
  font-size: 1.5rem;
`;

const FormTitle = ({ jobId, title, editorMsg }) => {
  const navigate = useNavigate();
  const { formValues, companyLogo /*formErrors,*/ } = useJobContext();
  const queryClient = useQueryClient();

  const [showShareJd, setShowShareJd] = useState(false);
  const [buttonState, setButtonState] = useState(false);

  const jobUpdateMutation = useMutation(({ jobId, jdObj }) => updateJD({ jobId, jdObj }), {
    onSuccess: () => queryClient.invalidateQueries(['job']),
  });

  //  const formFieldsErrorCount = getObjectKeysValue(formErrors);

  // Validating form fields for Save button
  useEffect(() => {
    // TODO: use isFormValid from context
    if (
      // isFormValid &&
      formValues.jobTitle.length > 3 &&
      formValues.jobTitle.length <= JOBTITLE_LENGTH &&
      formValues.companyName.length >= 1 &&
      formValues.companyName.length <= COMPANY_LENGTH &&
      formValues.companyLink.length > 0 &&
      formValues.location.length > 0 &&
      formValues.location.length <= LOCATION_LENGTH &&
      formValues.shortDescription.length > 0 &&
      formValues.shortDescription.length <= SHORT_DESC_LENGTH &&
      formValues.linkToApply.length > 0 &&
      editorMsg.length > 0 &&
      companyLogo?.url &&
      companyLogo?.name
      // formFieldsErrorCount === 0
    ) {
      setButtonState(false);
    } else {
      setButtonState(true);
    }
  }, [formValues, editorMsg]);

  useEffect(() => {
    if (jobUpdateMutation?.data?.status === 201) {
      toast.success('JD Saved Successfully', {
        position: 'top-center',
      });
    } else if (jobUpdateMutation?.data?.error) {
      toast.error('Something went wrong', {
        position: 'top-center',
      });
    }
  }, [jobUpdateMutation?.data?.status]);

  const handleSave = async () => {
    try {
      const jdObj = {
        ...formValues,
        companyLogo,
        detailedDescription: editorMsg,
        imageUrl: 'dummy',
      };
      await jobUpdateMutation.mutate({ jobId, jdObj });
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = () => {
    navigate('/');
  };

  return (
    <>
      {showShareJd && (
        <ShareJd title={title} jobId={jobId} closeHandler={() => setShowShareJd(false)} />
      )}
      <Flex>
        <Title>{`${jobId ? title : 'Create Job Post'}`}</Title>
        {jobId ? (
          <Flex gap="1rem">
            <Button
              bg="transparent"
              height="2.25rem"
              width="110px"
              padding="8px 5px"
              color={theme.primaryBlue}
              fontWeight="600"
              fontSize="14px"
              clickHandler={() => setShowShareJd(true)}>
              <Flex gap=".47rem" jc="center">
                <ShareIcon fill={theme.primaryBlue} />
                <span>Share JD</span>
              </Flex>
            </Button>

            <Button
              disabled={buttonState}
              type="button"
              height="2.25rem"
              bg={buttonState ? theme.grey2 : theme.primaryBlue}
              color={theme.white}
              padding="7px 15px"
              width="auto"
              border={`1px solid ${theme.grey1}`}
              fontWeight="600"
              fontSize=".875rem"
              clickHandler={handleSave}>
              Save Changes
            </Button>
            <Button
              type="button"
              height="2.25rem"
              bg="transparent"
              border={`1px solid ${theme.grey1}`}
              color={theme.grey1}
              padding="7px 15px"
              fontWeight="600"
              fontSize=".875rem"
              width="auto"
              clickHandler={handleClose}>
              Close
            </Button>
          </Flex>
        ) : (
          <></>
        )}
      </Flex>
    </>
  );
};

FormTitle.defaultProps = {
  jobId: '',
  title: '',
};

FormTitle.propTypes = {
  jobId: PropTypes.string,
  title: PropTypes.string,
  editorMsg: PropTypes.string,
};

export default FormTitle;
