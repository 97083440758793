import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { getLogo } from '../services/jobs.services';
import styled from 'styled-components';
import { Flex } from '../styles/Flex.styled';
import { useJobContext } from '../context';

const Image = styled.img`
  width: 1.5rem;
  margin-right: 1rem;
`;

const Span = styled.span``;
const Option = props => {
  const { data } = props;
  return (
    <components.Option {...props}>
      <Flex jc="flex-start" gap="1rem">
        <Image src={data.data.logo} />
        <Span>{data.value}</Span>
      </Flex>
    </components.Option>
  );
};
Option.propTypes = {
  children: PropTypes.string,
  props: PropTypes.object,
  data: PropTypes.object,
};

const SelectOptions = ({ companyLogo, defaultOptions }) => {
  const { url, name } = companyLogo;

  const [selectOption, setSelectOption] = useState();
  const { selectCompanyLogo } = useJobContext();

  useEffect(() => {
    // Setting the URL if it is in the EDIT mode

    setSelectOption(prev => ({ ...prev, value: name, label: name }));
    selectCompanyLogo({ url, name });
  }, [companyLogo.name]);

  const handleChangeSelect = ({ value, data, label }) => {
    setSelectOption(prev => ({ ...prev, value, label }));
    selectCompanyLogo({ url: data.logo, name: value });
  };

  const loadOptions = async (searchValue, callback) => {
    try {
      const result = await getLogo(searchValue);
      if (result.length === 0) {
        callback([
          {
            value: 'Not available',
            label: 'Not available',
            data: { domain: '', logo: 'dummy' },
          },
        ]);
      } else {
        callback(
          result.map(el => ({
            value: el.name,
            label: el.name,
            data: { domain: el.domain, logo: el.logo },
          }))
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const colorStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '8px',
      border: '1px solid #EDEEF6',
      borderColor: '#edeef6',
      height: '34px',
      fontSize: '.875rem',
      fontWeight: '400',
      fontFamiliy: 'Inter',
      color: '#14142a',
      width: '100%',
    }),
    option: (styles, obj) => {
      return {
        ...styles,
        cursor: 'pointer',
        zIndex: 999,
        color: '#14142a',
        backgroundColor: obj.isFocused ? 'lightblue' : 'white',
        width: '300px',
        minWidth: '250px',
        overflowX: 'hidden',
      };
    },
    placeholder: provided => ({
      ...provided,
      fontSize: '14px',
      display: 'block',
      margin: '-4px 2px 0 2px',
      top: '62.5%',
      color: 'black',
    }),
    /* menuList: styles => ({
      ...styles,
      zIndex: 999,
      padding: '0 auto',
    }),*/
  };
  return (
    <>
      <AsyncSelect
        // onInputChange={handleInputChange}
        loadOptions={loadOptions}
        placeholder={'Enter a company name'}
        onChange={handleChangeSelect}
        styles={colorStyles}
        components={{ Option }}
        value={selectOption}
        defaultOptions={defaultOptions}
        // defaultInputValue={selectOption.label}
      />
    </>
  );
};

SelectOptions.defaultProps = {
  companyLogo: {},
};
SelectOptions.propTypes = {
  url: PropTypes.string,
  name: PropTypes.string,
  companyLogo: PropTypes.object,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      data: PropTypes.object,
    })
  ),
};

export default React.memo(SelectOptions);
