import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';
import { theme } from '../../../styles/GlobalStyles';
import { AnchorTag, Flex, HR, Para } from '../../../styles/Flex.styled';

import {
  CompanyLogo,
  LightningIcon,
  LinkIcon,
  MapIcon,
  // SettingsIcon,
  UserIcon,
} from '../../../components/icons';
import { useJobContext } from '../../../context';

import PreviewFooter from './PreviewFooter';
import { contentsWithinLimit } from '../../../common/helper';
import { COMPANY_LENGTH, JOBTITLE_LENGTH, LOCATION_LENGTH } from '../../../common/constant';

const PreviewWrapper = styled.section`
  flex: 1.5;
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* gap: 0.25rem; */
  height: 100%;
  border-radius: 10px;
`;

const AsideWrapper = styled.aside`
  width: 100%;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  & section:first-child {
    flex: 0.75;
  }
  & section:nth-child(2) {
    flex: 0.25;
  }
`;

const Title = styled.h1`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ size }) => size};
  margin-bottom: ${({ mb }) => mb};
  color: ${({ color }) => color || theme.black};
`;

const SmallHeading = styled.span`
  font-size: 1.125rem;
  font-weight: 600;
  color: ${({ theme }) => theme.black};
`;

const PreviewSection = styled.article`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.75rem;
  width: 100%;
  padding: 1rem 1.5rem;
  background-color: ${theme.white};
  border-radius: 10px;
  flex-grow: 1;
`;

const Image = styled.img`
  width: 90%;
  height: auto;
  max-height: 100%;
  min-height: 20px;
`;
/*
const EmptyDiv = styled.div`
  height: 40px;
  background-color: '#fff';
  width: 100%;
`;*/
const DetailedDescription = styled.article`
  width: 100%;
  background-color: ${({ bg }) => bg};
  padding: ${({ padding }) => padding || '0'};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  height: ${({ height }) => height || '398px'};
  line-height: ${({ lineHeight }) => lineHeight};
  overflow: auto;
  margin-bottom: 0.5rem;
  background-color: ${({ backgroundColor }) => backgroundColor || 'none'};
  word-break: break-all;

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1rem 0;
  }

  & h1 {
    font-size: 2.5rem;
  }
  & h2 {
    font-size: 2rem;
  }
  & h3 {
    font-size: 1.5rem;
  }
  & h4 {
    font-size: 1rem;
  }
  & h5 {
    font-size: 0.85rem;
  }
  & h6 {
    font-size: 0.75rem;
  }
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: #5b5a5a;
    border: 1px solid slategrey;
    border-radius: 8px;
  }

  & p {
    margin: 0.5rem 0;
  }
`;

const Preview = ({ editorMsg, editMode }) => {
  const { formValues, companyLogo } = useJobContext();

  return (
    <PreviewWrapper>
      <Flex direction="row nowrap" width="100%" padding="0 5px 0 0" mb="1rem">
        <Para fontSize="1rem" mb="0" fontWeight="500" color={theme.black}>
          Your generated Job description
        </Para>
        {/* <Flex mb="0" gap=".338rem" jc="center">
          <SettingsIcon />
          <Para color={theme.grey2} fontSize=".875rem">
            Settings
          </Para>
        </Flex> */}
      </Flex>
      <PreviewSection bg={theme} boxShadow={theme.previewBoxShadow}>
        <AsideWrapper id="ogImage">
          <Flex direction="column" ai="flex-start" gap=".625rem">
            <Flex gap=".5rem" ai="flex-end" direction="row wrap">
              <Title
                size="1.5rem"
                mb="0"
                color={formValues?.jobTitle ? theme.black : theme.grey3}
                fontWeight={formValues?.jobTitle ? '700' : '500'}>
                {contentsWithinLimit(formValues?.jobTitle, JOBTITLE_LENGTH) || 'Job Title'}
              </Title>
              {formValues?.companyName && (
                <SmallHeading>
                  at {contentsWithinLimit(formValues?.companyName, COMPANY_LENGTH)}
                </SmallHeading>
              )}
            </Flex>
            <Para
              fontSize=".85rem"
              color={formValues?.shortDescription ? theme.grey1 : theme.primaryBlue}
              fontWeight={formValues?.shortDescription ? '500' : '400'}>
              {contentsWithinLimit(formValues?.shortDescription) ||
                'This is a short job description to catch the attention of your target audience. Keep it simple.'}
            </Para>
          </Flex>
          <Flex jc="flex-end">
            {companyLogo?.url ? (
              companyLogo?.url === 'dummy' ? (
                <CompanyLogo />
              ) : (
                <Image src={companyLogo?.url} width="80px" height="80px" borderRadius="50%" />
              )
            ) : (
              <UserIcon />
            )}
          </Flex>
        </AsideWrapper>
        <Flex direction="row nowrap" gap="1rem" ai="center" mb="4px">
          <Flex direction="row nowrap" gap=".5rem">
            <LinkIcon fill={formValues?.companyLink ? theme.black : theme.grey4} />
            <Para fontSize=".75rem" color={formValues?.companyLink ? theme.black : theme.grey4}>
              {formValues?.companyLink || 'Company Link'}
            </Para>
          </Flex>
          <Flex direction="row nowrap" gap=".5rem">
            <MapIcon fill={formValues?.location ? theme.black : theme.grey4} />
            <Para fontSize=".75rem" color={formValues?.location ? theme.black : theme.grey4}>
              {contentsWithinLimit(formValues?.location, LOCATION_LENGTH) || 'Location'}
            </Para>
          </Flex>
          <Flex gap=".5rem">
            <LightningIcon fill={formValues?.location ? '#106FDF' : theme.grey4} />
            <Para color={formValues?.jobTitle ? theme.black : theme.grey4} fontSize=".75rem">
              {formValues?.jobTitle ? 'Actively Hiring' : 'Hiring Status'}
            </Para>
          </Flex>
        </Flex>
        <AnchorTag
          href={formValues?.linkToApply || ''}
          fontSize="14px"
          fontWeight="600"
          target="_blank"
          height="2.25rem"
          width="7.5rem"
          backgroundColor={formValues?.linkToApply ? theme.blue4 : theme.grey2}
          color={theme.white}
          borderRadius="10px"
          pointerEvents={formValues?.linkToApply ? 'auto' : 'none'}
          cursor={formValues?.linkToApply ? 'pointer' : 'none'}>
          Apply now
        </AnchorTag>
        <HR margin=".5rem 0rem" />

        {editorMsg ? (
          <></>
        ) : (
          <Para color={theme.grey4} fontWeight="600" fontSize="1.125rem">
            Detailed Job description
          </Para>
        )}
        {!editorMsg ? (
          <DetailedDescription
            color={theme.grey2}
            fontWeight="400"
            fontSize="1rem"
            lineHeight="24px"
            backgroundColor="rgba(243, 243, 243, 0.5)"
            height={editMode ? '475px' : '479px'}
            padding="1rem">
            This is a long job description (you may delete this text). Use it to sell your opening.
            Here are a few tips: This is a long job description (you may delete this text).
          </DetailedDescription>
        ) : (
          <DetailedDescription
            fontSize=".875rem"
            fontWeight="400"
            lineHeight="24px"
            padding="1rem 0"
            height={editMode ? '475px' : '475px'}
            dangerouslySetInnerHTML={{ __html: editorMsg }}></DetailedDescription>
        )}
        <PreviewFooter linkToApply={formValues?.linkToApply || ''} />
      </PreviewSection>
    </PreviewWrapper>
  );
};

Preview.propTypes = {
  editorMsg: PropTypes.string,
  editMode: PropTypes.bool,
};

export default Preview;
