import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Formik, Form, useField, useFormikContext } from 'formik';

import styled from 'styled-components';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { theme } from '../../../styles/GlobalStyles';
import { useJobContext } from '../../../context';
import { Flex, HR, Para } from '../../../styles/Flex.styled';
import { checkIfNameExist, createJob, getLogo, uploadJD } from '../../../services/jobs.services';

import SelectOptions from '../../../components/Select';
// import DraftEditor from '../../../components/textEditor/DraftEditor';
import { validationYup } from '../../../validations/jobValidation';
import { contentsWithinLimit, getObjectKeysValue } from '../../../common/helper';
import SuccessAnimate from '../../../assets/icons/successAnimate.gif';
import Modal from '../../../components/Modal';
import CreateJobError from '../../../components/CreateJobError';
import QuillEditor from '../../../components/quillEditor/QuillEditor';
import Button from '../../../components/Button';
import { fieldLengths } from '../../../common/constant';

const SHORT_DESC_LENGTH = 200;

const Section = styled.section`
  width: 375px;
  height: 275px;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 1px 46px rgba(20, 20, 42, 0.07);
  position: relative;
`;

const SuccessMsg = styled.p`
  position: absolute;
  left: 30px;
  bottom: 60px;
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  margin: 0;
`;

const Image = styled.img`
  width: ${({ width }) => width};
  height: ${({ height }) => height || 'auto'};
  border-radius: ${({ borderRadius }) => borderRadius || 'auto'};
`;

const FormContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: column nowrap;
  flex: 1.5;
  padding: 1rem;
  background-color: ${({ theme }) => theme.white};
  border-radius: 10px;
`;

const JDForm = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.25rem;
`;

const FormControl = styled.div`
  display: flex;
  flex-flow: column nowrap;
  /* margin-bottom: ${({ mb }) => mb || '0rem'}; */
  position: relative;
  width: ${({ width }) => width || '50%'};
`;

const FormError = styled.div`
  color: ${({ theme }) => theme.red};
  font-size: 0.75rem;
  margin-top: 0.25rem;
`;
const Title = styled.h1`
  font-weight: 500;
  font-size: ${({ size }) => size};
  /* margin-bottom: ${({ mb }) => mb}; */
  color: ${({ color }) => color || theme.black};
`;

const PlaceholderWrapper = styled.span`
  left: 4px;
  top: 33px;
  width: 40px;
  position: absolute;
  color: #6e7191;
  font-size: 0.8rem;
  font-weight: 400;
`;
const InputText = styled.input`
  border-radius: 8px;
  width: ${({ width }) => width || '100%'};
  height: 36px;
  padding: 6px;
  padding-left: ${({ paddingLeft }) => paddingLeft};
  border: ${({ borderColor }) => `1px solid ${borderColor}`};
  font-family: inherit;
  background-color: #fff !important;
  color: #14142a !important;
  font-size: 0.875rem;
  font-weight: 400;
  outline-color: ${({ borderColor }) => borderColor};
  -webkit-appearance: none !important;
  &::placeholder {
    color: '#6e7191';
    padding: 5px;
  }
  &:-internal-autofill-selected {
    background-color: rgb(255, 255, 255) !important;
    background-image: none !important;
    color: rgb(0, 0, 0) !important;
  }
`;

const Label = styled.label`
  color: #4e4b66;
  margin-bottom: 6px;
  font-size: 0.875rem;
  font-weight: 400;
`;

const UploadLabel = styled.label`
  padding: 12px 16px;
  background-color: transparent;
  border: ${({ border }) => border || '1px solid blue'};
  color: ${color => color};
  font-size: 0.875rem;
  font-weight: 600;
  border-radius: ${theme.borderRadius};
  max-height: 40px;
  min-width: 100px;
  cursor: pointer;
`;
const TextArea = styled.textarea`
  resize: none;
  padding: 10px;
  font-family: inherit;
  width: 100%;
  height: 100px;
  overflow-y: auto;
  border: 1px solid #d9dbe9;
  border-radius: 8px;
  color: ${({ theme }) => theme.black};
  line-height: 20px;
  font-size: 0.875rem;
  font-weight: 500;
  outline-color: ${({ borderColor }) => borderColor};

  &::placeholder {
    font-weight: 400;
    color: ${theme.grey2};
    line-height: 16px;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }
`;

const SpanCounter = styled.span`
  position: absolute;
  right: ${({ right }) => right || '6px'};
  bottom: ${({ bottom }) => bottom || '4px'};
  top: ${({ top }) => top || 'unset'};
  font-size: ${({ fontSize }) => fontSize || '0.75rem'};
  color: ${({ theme }) => theme.grey2};
  font-weight: 400;
`;

const Container = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
  /* margin: 1rem 0; */
`;

const EditorBox = styled.div`
  word-break: break-all;
`;

const FormObserver = ({ toggleIsFormValid }) => {
  const { values, isValid } = useFormikContext();

  const { handleChange } = useJobContext();

  const testRef = useRef(true);

  useEffect(() => {
    if (!testRef.current) {
      // TODO: Try using useMergeState hook for this operation
      handleChange({
        jobTitle: values.jobTitle.trim(),
        companyName: values.companyName.trim(),
        shortDescription: values.shortDescription.trim(),
        shortDescriptionLength: 200,
        companyLink: values.companyLink.trim(),
        location: values.location.trim(),
        linkToApply: values.linkToApply.trim(),
      });
      toggleIsFormValid(isValid);
    }
    testRef.current = false;
  }, [values]);
  return null;
};

FormObserver.propTypes = {
  toggleIsFormValid: PropTypes.func.isRequired,
};

const TextInput = ({ label, ...props }) => {
  const { setFormError } = useJobContext();
  const [field, meta, helpers] = useField(props.name);
  const [error, setError] = useState(null);

  let characterLenght = null;

  if (props.isCharPlaceholder) {
    characterLenght = fieldLengths[field.name];
    field.value = contentsWithinLimit(field.value, characterLenght);
  }

  if (props.https) {
    if (field.value.includes('https') || field.value.includes('http'))
      field.value = field.value.slice(8);
  }
  const handleBlur = async () => {
    if (field.name === 'jobTitle' && field.value) {
      const isJobTitleDuplicate = await checkIfNameExist(field.value);

      if (isJobTitleDuplicate.isExists) {
        setError(`JobTitle "${field.value}" is already exists`);
        setFormError({ [field.name]: `JobTitle ${field.value} already exists` });
      } else {
        setError(null);
        setFormError({ [field.name]: '' });
      }
    }
    helpers.setTouched(true, true);
    // props.setTouched({ ...props.touched, [field.name]: true }, true);
  };
  return (
    <FormControl>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      {props.https && <PlaceholderWrapper borderColor={theme.grey5}>https://</PlaceholderWrapper>}
      <InputText
        paddingLeft={props.https ? '48px' : '6px'}
        borderColor={theme.grey5}
        className="text-input"
        {...field}
        {...props}
        onBlur={handleBlur}
      />
      {props.isCharPlaceholder && (
        <SpanCounter
          right="4px"
          bottom="0"
          top="45px"
          fontSize=".6rem">{`${field?.value?.length} / ${characterLenght}`}</SpanCounter>
      )}
      {meta.touched && meta.error ? <FormError>{meta.error}</FormError> : null}
      {/* {props.status && <FormError>{props.status.jobTitle}</FormError>} */}
      {error ? <FormError>{error}</FormError> : null}
    </FormControl>
  );
};

TextInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  https: PropTypes.bool,
  isCharPlaceholder: PropTypes.bool,
  // setTouched: PropTypes.func,
  // touched: PropTypes.object,
};
const SelectElement = ({ companyLogo, label, ...props }) => {
  const [field, meta] = useField(props);
  const [initialOptions, setInitialOptions] = useState([]);

  useEffect(() => {
    getLogo('a').then(res => {
      setInitialOptions(
        res.map(el => ({
          value: el.name,
          label: el.name,
          data: { domain: el.domain, logo: el.logo },
        }))
      );
    });
  }, []);

  return (
    <FormControl>
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <SelectOptions
        {...field}
        {...props}
        companyLogo={companyLogo}
        defaultOptions={initialOptions}
      />
      {meta.touched && meta.error ? <FormError>{meta.error}</FormError> : null}
    </FormControl>
  );
};

SelectElement.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  companyLogo: PropTypes.object,
};
const TextAreaElement = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  field.value = contentsWithinLimit(field.value);

  return (
    <FormControl mb="1rem" width="100%">
      <Label htmlFor={props.id || props.name}>{label}</Label>
      <TextArea borderColor={theme.grey5} {...field} {...props} />
      <SpanCounter>{`${field?.value?.length} / ${SHORT_DESC_LENGTH}`}</SpanCounter>
      {meta.touched && meta.error ? <FormError>{meta.error}</FormError> : null}
    </FormControl>
  );
};

TextAreaElement.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
};
const JobForm = ({ jobDetails, handleEditorMsg, editorMsg }) => {
  const [fileContent, setFileContent] = useState(null);
  const [modalActive, setModalActive] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialFormState = {
    jobTitle: jobDetails.jobTitle || '',
    companyName: jobDetails.companyName || '',
    shortDescription: jobDetails.shortDescription || '',
    shortDescriptionLength: 200,
    companyLink: jobDetails.companyLink || '',
    location: jobDetails.location || '',
    companyLogo: jobDetails.companyLogo || {},
    linkToApply: jobDetails.linkToApply || '',
    detailedDescription: jobDetails.detailedDescription || '',
  };

  const navigate = useNavigate();

  const { handleChange, companyLogo, toggleIsFormValid, formErrors } = useJobContext();

  const formFieldsErrorCount = getObjectKeysValue(formErrors);

  const queryClient = useQueryClient();

  const addJobMutate = useMutation(createJob, {
    onSuccess: val => {
      if (checkError(val)) return;
      // Invalidates cache and fetch
      queryClient.invalidateQueries('job');
    },
  });

  useEffect(() => {
    // Context updated of the AppToaster
    let interval;
    if (addJobMutate?.data?.status === 201) {
      setModalActive(true);
      interval = setTimeout(() => {
        setModalActive(false);
        navigate(`/edit-job/${addJobMutate.data?.data?._id}`);
      }, 3000);
    }
    return () => {
      clearTimeout(interval);
    };
  }, [addJobMutate?.data?.status]);

  const checkError = data => {
    if (data?.code && data.code === 'ERR_BAD_REQUEST') {
      setShowErrorModal(true);
      return true;
    }
    return false;
  };

  // Functionalities for Submitting the form
  const handleSubmit = async values => {
    setIsSubmitting(true);
    await addJobMutate.mutateAsync({
      ...values,
      companyLogo: companyLogo,
      detailedDescription: editorMsg,
      imageUrl: 'dummy',
    });
    setIsSubmitting(false);
  };

  // Upload JD - Loading the detailed desc in the Context
  const handleFile = async e => {
    const formData = new FormData();
    const file = e.target.files[0];

    /*console.log('File details', {
      type: file.type,
      boolType: ['doc', 'docx'].includes(file.name.split('.')[1]),
    });*/

    if (file.type === 'application/pdf' || ['doc', 'docx'].includes(file.name.split('.')[1])) {
      formData.append('file', file, file.name);

      const res = await uploadJD(formData);

      if (res.status === 201) {
        toast.success('File uploaded successfully', { position: 'top-center', autoClose: true });

        setFileContent(res.data.value);
      }
    } else {
      toast.error('File format is not supported.', { position: 'top-center', autoClose: true });
    }
  };

  // If it is in the EDIT state, loading the FORM details in the Context
  useEffect(() => {
    setFileContent(jobDetails?.detailedDescription);
    if (jobDetails?.jobTitle) {
      handleChange({
        jobTitle: jobDetails?.jobTitle,
        companyName: jobDetails?.companyName,
        shortDescription: jobDetails?.shortDescription,
        shortDescriptionLength: 200,
        companyLink: jobDetails?.companyLink,
        location: jobDetails?.location,
        linkToApply: jobDetails?.linkToApply,
      });
    }
  }, [jobDetails]);

  // To handle blank spaces and break statements in editor
  const isInvalidDescription = useMemo(() => {
    if (!editorMsg || !document.getElementsByClassName('ql-editor')?.[0]?.textContent.trim()) {
      // toggleIsFormValid(false)
      return true;
    }
    // toggleIsFormValid(true)
    return false;
  }, [editorMsg]);

  return (
    <>
      {showErrorModal && <CreateJobError />}
      {modalActive && (
        <Modal>
          <Section>
            <Image src={SuccessAnimate} alt="Success icon" width="180px" />
            <SuccessMsg color={theme.green} fontSize="1.375rem" fontWeight="600" lineHeight="26px">
              JD Created Successfully
            </SuccessMsg>
          </Section>
        </Modal>
      )}
      <FormContainer>
        <Formik
          initialValues={initialFormState}
          validationSchema={validationYup}
          enableReinitialize
          onSubmit={handleSubmit}
          validateOnMount>
          {({ isValid /* setTouched, touched*/ }) => (
            <JDForm>
              <Title mb="1rem" size="1rem" color={theme.black}>
                Job description details
              </Title>
              <FormObserver toggleIsFormValid={toggleIsFormValid} />

              <Flex jc="space-between" ai="flex-start" gap="1.5rem">
                <TextInput
                  label="Job title"
                  placeholder="Product designer"
                  type="text"
                  name="jobTitle"
                  mandatory={true}
                  isCharPlaceholder={true}
                  // setTouched={setTouched}
                  // touched={touched}
                />
                <TextInput
                  label="Company name"
                  placeholder="Enter company name"
                  type="text"
                  name="companyName"
                  isCharPlaceholder={true}
                />
              </Flex>

              <TextAreaElement
                label="Short job description"
                placeholder="This is a short job description to catch the attention of your target audience. Keep it simple."
                type="text"
                name="shortDescription"
              />

              <Flex jc="space-between" ai="flex-start" gap="1.4rem">
                <TextInput
                  https={true}
                  label="Company link"
                  placeholder=""
                  type="text"
                  name="companyLink"
                />
                <TextInput
                  label="Location"
                  placeholder=""
                  type="text"
                  name="location"
                  isCharPlaceholder={true}
                />
              </Flex>
              <Flex jc="space-between" ai="flex-start" gap="1.4rem">
                <SelectElement
                  label="Logo"
                  placeholder="Enter a company name"
                  name="companyLogo"
                  companyLogo={jobDetails?.companyLogo || {}}
                />

                <TextInput
                  https={true}
                  label="Link to apply"
                  placeholder=""
                  type="text"
                  name="linkToApply"
                />
              </Flex>
              {/* <label>Logo</label> */}

              <HR margin="0" />
              <Container>
                <Flex direction="column nowrap" ai="flex-start" gap="4px">
                  <Title mb="0" size="1rem" color={theme.black}>
                    Detailed Job description
                  </Title>
                  <Para fontSize="12px" fontWeight="400" lineHeight="14px">
                    File Format supported: .docx and .pdf
                  </Para>
                </Flex>
                <UploadLabel
                  htmlFor="upload"
                  color={theme.primaryBlue}
                  border={`1px solid ${theme.buttonBorderColor}`}>
                  Upload JD
                </UploadLabel>
                <input
                  style={{ display: 'none' }}
                  type="file"
                  id="upload"
                  onChange={handleFile}
                  accept=".doc, .docx"
                />
              </Container>
              <EditorBox>
                <QuillEditor handleEditorMsg={handleEditorMsg} content={fileContent || ''} />
              </EditorBox>
              {!jobDetails?._id ? (
                <Button
                  type="submit"
                  width="180px"
                  height="36px"
                  fontSize=".875rem"
                  fontWeight="600"
                  bg={
                    isSubmitting || !isValid || isInvalidDescription || formFieldsErrorCount > 0
                      ? theme.grey2
                      : theme.primaryBlue
                  }
                  color={theme.white}
                  padding="10px 16px"
                  disabled={
                    isSubmitting || !isValid || isInvalidDescription || formFieldsErrorCount > 0
                  }
                  margin="auto">
                  {!isSubmitting ? 'Create Job Post' : 'Submitting'}
                </Button>
              ) : (
                <></>
              )}
            </JDForm>
          )}
        </Formik>
      </FormContainer>
    </>
  );
};

JobForm.defaultProps = {
  jobDetails: {},
};
JobForm.propTypes = {
  jobDetails: PropTypes.object,
  handleEditorMsg: PropTypes.func,
  editorMsg: PropTypes.string,
};

export default JobForm;
