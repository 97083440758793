import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Para } from '../../../styles/Flex.styled';
import { theme } from '../../../styles/GlobalStyles';
import Button from '../../Button';

const Input = styled.input`
  width: ${({ width }) => width || '100%'};
  background: #ffffff;
  border: ${({ border }) => border};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #14142a;
  height: 36px;
  padding: ${({ padding }) => padding};

  &::placeholder {
    font-weight: 500;
  }

  &:focus-visible {
    outline: none;
  }
`;
const Label = styled.label`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: 17px;
  color: ${({ color }) => color};
  margin: 0;
`;

const CandidateCard = ({ confirmUrlGeneration }) => {
  const [inputName, setInputName] = useState('');

  const handleOnChange = async e => {
    const name = e.target.value.slice(0, 25);
    setInputName(name);
  };

  const handleConfirm = () => {
    confirmUrlGeneration(inputName);
  };

  const buttonBackground = inputName.length > 3 ? theme.primaryBlue : theme.disableGrey;
  return (
    <Flex direction="column nowrap" gap="1rem" ai="flex-start">
      <Para fontSize="1rem" fontWeight="400" lineHeight="20px" color={theme.black}>
        Please provide candidate name to create personalized JD
      </Para>
      <Flex gap=".5rem" direction="column" ai="flex-start" width="100%" mb="6px">
        <Label color={theme.grey1} fontSize=".875rem" fontWeight="400">
          Candidate name
        </Label>

        <Input
          value={inputName}
          onChange={handleOnChange}
          placeholder="Enter name"
          padding="9px"
          border={`1px solid ${theme.greyBorder}`}
        />
      </Flex>
      <Flex jc="center" width="100%">
        <Button
          disabled={inputName.length > 3 ? false : true}
          clickHandler={handleConfirm}
          bg={buttonBackground}
          color={theme.white}
          border={`none`}
          boxShadow="0px 4px 10px rgba(44, 90, 246, 0.23)"
          fontSize="13px"
          fontWeight="500"
          lineHeight="15px"
          height="32px"
          width="120px">
          Confirm
        </Button>
      </Flex>
    </Flex>
  );
};

CandidateCard.propTypes = {
  confirmUrlGeneration: PropTypes.func,
};

export default CandidateCard;
