import { ALPHA_REGEX, HEADER_TYPES, SHORT_DESC_LENGTH, URL_VALIDATION } from './constant.js';

export function isArrayEmpty(arr) {
  return !(arr && typeof arr === 'object' && arr.length);
}

export const parseCookieDetails = cookiesInfo => {
  const csrfToken =
    cookiesInfo &&
    cookiesInfo.cookieObj &&
    cookiesInfo.cookieObj.JSESSIONID &&
    cookiesInfo.cookieObj.JSESSIONID.replace(/\\\s|"/g, '');
  const cookie = cookiesInfo && cookiesInfo.cookieStr && cookiesInfo.cookieStr.replace(/\\\s/g, '');
  return { csrfToken, cookie };
};

export const findCurrentBlockStyle = style => {
  const newEl = HEADER_TYPES.find(el => {
    if (style === el.style) {
      return true;
    }
  });
  return { label: newEl?.label, value: newEl?.label };
};

export const contentsWithinLimit = (content = '', contentLength = SHORT_DESC_LENGTH) => {
  return content?.length > contentLength ? content.slice(0, contentLength) : content;
};

export const getAuthTimeZone = () => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  };
};

export const getObjectKeysValue = obj => {
  let count = 0;

  for (let key of Object.keys(obj)) {
    if (obj[key].length > 0) count++;
  }
  return count;
};

export const isValidUrl = val => {
  if (val?.includes('https://')) return URL_VALIDATION.test(val);
  return URL_VALIDATION.test(`https://${val}`);
};

export const isAlphaCharacters = val => val?.match(ALPHA_REGEX);
