import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Flex, Para, Span } from '../../../styles/Flex.styled';
import { theme } from '../../../styles/GlobalStyles';
import CopyText from './CopyText';
import { contentsWithinLimit } from '../../../common/helper';
import { REACHOUT_MSG_LENGTH } from '../../../common/constant';
import useAutosizeTextarea from '../../../hooks/useAutosizeTextarea';

const Label = styled.label`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  line-height: 17px;
  color: ${({ color }) => color};
  margin: 0;
`;

const ParaTrunc = styled.p`
  padding: ${({ padding }) => padding || 'unset'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  color: ${({ color }) => color};
  font-weight: ${({ fontWeight }) => fontWeight};
  width: ${({ width }) => width};
  font-size: ${({ fontSize }) => fontSize};
  text-decoration: ${({ textDecoration }) => textDecoration || 'unset'};
  flex: 8;
`;

const ToggleStatus = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 10px;
  background: ${theme.green};
  color: ${theme.white};
  font-size: 12px;
  height: 36px;
`;
const TextAreaEl = styled.textarea`
  width: 100%;
  overflow: auto;
  height: clamp(40px, 50px, 150px);
  resize: none;
  font-family: inherit;
  line-height: 24px;
  color: ${({ color }) => color};
  font-weight: 500;
  border: none;
  outline-color: #fff;
  padding-left: 4px;

  &::-webkit-scrollbar {
    width: 0.35rem;
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
`;

const CopyCard = ({ title, candidateName, shortendUrl }) => {
  const [isUrlCopied, setIsUrlCopied] = useState(false);
  const [isMsgCopied, setIsMsgCopied] = useState(false);
  const [dummy, setDummy] = useState(0);

  const [reachoutMsg, setReachoutMsg] = useState(
    `We need you to join our talented team as a ${title} 🙂`
  );

  const inputRef = useRef();
  const msgRef = useRef();
  const textareaRef = useRef();

  // Hook for setting the Textarea height dynamically
  useAutosizeTextarea(textareaRef, reachoutMsg);

  useEffect(() => {
    if (inputRef.current) {
      setDummy(1);
    }
    console.log('Dummy value', dummy);
  }, [inputRef.current, msgRef.current]);

  useEffect(() => {
    if (!isUrlCopied) return;
    setTimeout(() => setIsUrlCopied(false), 3000);
  }, [isUrlCopied]);

  useEffect(() => {
    if (!isMsgCopied) return;
    setTimeout(() => setIsMsgCopied(false), 3000);
  }, [isMsgCopied]);

  const toggleUrlButton = boolValue => setIsUrlCopied(boolValue);
  const toggleMsgButton = boolValue => setIsMsgCopied(boolValue);

  const handleEditChange = event => {
    let msg = event.target.value;
    setReachoutMsg(contentsWithinLimit(msg, REACHOUT_MSG_LENGTH));
  };

  return (
    <Flex ai="flex-start" gap="1rem" direction="column nowrap" width="100%">
      <Para color={theme.black} lineHeight="1.18rem">
        Personalized JD URL has been created.
      </Para>

      <Flex gap=".5rem" direction="column" ai="flex-start" width="100%">
        <Label color={theme.grey1} fontSize=".875rem" fontWeight="400">
          Job post URL (Share this URL as a social media post )
        </Label>
        <Flex
          jc="space-between"
          border={`1px solid ${theme.greyBorder}`}
          width="100%"
          height="36px"
          padding="10px 0 5px 5px"
          borderRadius="8px"
          gap=".3rem">
          <ParaTrunc
            width="100%"
            color={theme.black}
            fontSize=".875rem"
            fontWeight="500"
            ref={inputRef}>
            {shortendUrl}
          </ParaTrunc>
          {inputRef.current &&
            (isUrlCopied ? (
              <ToggleStatus>Copied</ToggleStatus>
            ) : (
              <CopyText
                text="Copy url"
                url={inputRef.current.innerText}
                callback={toggleUrlButton}
                padding={'0 5px 0 0'}
              />
            ))}
        </Flex>
      </Flex>

      <Flex gap=".5rem" direction="column" ai="flex-start" width="100%">
        <Label color={theme.grey1} fontSize=".875rem" fontWeight="400">
          Reachout Message
        </Label>
        <Flex
          jc="space-around"
          direction="column nowrap"
          border={`1px solid ${theme.greyBorder}`}
          borderRadius="10px"
          padding=".5rem"
          width="100%">
          <Flex direction="column nowrap" ai="flex-start" gap=".5rem" width="100%">
            <Flex direction="column nowrap" ai="flex-start" gap=".25rem" width="100%" ref={msgRef}>
              <Para fontWeight="600" fontSize=".8rem">
                &nbsp;Hi {candidateName},
              </Para>
              <TextAreaEl
                color={theme.black}
                placeholder={`We need you to join our talented team as a ${title} 🙂`}
                onChange={handleEditChange}
                value={reachoutMsg}
                ref={textareaRef}
              />
              {
                <ParaTrunc
                  color={theme.primaryBlue2}
                  fontSize=".9rem"
                  fontWeight="500"
                  textDecoration="underline"
                  width="95%">
                  &nbsp;{shortendUrl}
                </ParaTrunc>
              }
            </Flex>
            <Flex jc="space-between" ai="flex-end" width="100%">
              <Span color={theme.grey2} fontSize="12px" fontWeight="400">
                {`${reachoutMsg.length} / ${REACHOUT_MSG_LENGTH}`}
              </Span>
              {msgRef?.current !== undefined &&
                (isMsgCopied ? (
                  <ToggleStatus>Copied</ToggleStatus>
                ) : (
                  <CopyText
                    text="Copy text"
                    content={msgRef}
                    border={`1px solid ${theme.primaryBlue}`}
                    borderRadius="8px"
                    padding="5px 10px"
                    callback={toggleMsgButton}
                  />
                ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

CopyCard.propTypes = {
  title: PropTypes.string,
  candidateName: PropTypes.string,
  shortendUrl: PropTypes.string,
};

export default CopyCard;
