import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { MainWrapper } from '../../styles/Flex.styled';
import { theme } from '../../styles/GlobalStyles';

// SVG
import SearchNoResults from '../../assets/icons/searchNoResults.svg';
import NoInactiveJobs from '../../assets/icons/NoInactiveJobs.svg';

// Services
import { getAllJobs } from '../../services/jobs.services';

import { Search } from '../../components/icons';
import Button from '../../components/Button';
import Pagination from './components/Pagination';
import NoJobsFound from '../../components/NoJobsFound';
import JobsList from './components/JobList';
import NoJobs from '../NoJobs';
import Loader from '../../components/Loader';
import useDebounce from '../../hooks/useDebounce';

const TEMPLATES_PER_PAGE = 6;

const SearchBox = styled.div`
  position: relative;
  display: flex;
  justify-content: right;
  align-items: center;
`;

const Searchinput = styled.input`
  background: ${({ color, theme }) => color || theme.white};
  border: 1px solid #edeef6;
  border-radius: 8px;
  font-size: 13px;
  height: 35px;
  outline: none;
  padding: 0 20px 0 35px;
  width: 200px;
  margin-right: 20px;
  height: 40px;
`;
const DisplayHeader = styled.header`
  align-items: baseline;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0;
`;

const Labelparent = styled.div`
  display: flex;
  margin: 5px 0px;
  padding: 10px 0;
`;

const Searchwrapper = styled.div`
  font-size: 17px;
  left: 10px;
  position: absolute;
  top: 12px;
`;

const Tab = styled.div`
  display: block;
  font-weight: 600;
  font-size: 13px;
  line-height: 25px;
  padding: 5px;
  margin-right: 45px;
  cursor: pointer;
`;

const TotalCount = styled.div`
  background: #e3e9ff;
  margin-left: 5px;
  padding: 4px;
  border-radius: 50px;
  width: 27px;
  height: 27px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const Border = styled.div`
  height: ${({ isActive }) => (isActive ? '3px' : '')};
  background-color: ${({ isActive }) => (isActive ? '#2934d0' : '')};
  margin-top: ${({ isActive }) => (isActive ? '3px' : '')};
  border-radius: ${({ isActive }) => (isActive ? '20px 20px 0 0' : '')};
`;

const Label = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: ${({ isActive }) => (isActive ? theme.primaryBlue : theme.grey1)};
  display: flex;
  align-items: center;
`;

const DisplayJobs = () => {
  const [isActive, setIsActive] = useState(true);
  const [skipLength, setSkipLength] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const debouncedValue = useDebounce(searchTerm, 500);

  const { isError, isLoading, data, refetch } = useQuery(
    ['job', debouncedValue, skipLength],
    () => getAllJobs(debouncedValue, TEMPLATES_PER_PAGE, skipLength, isActive),
    { enabled: false }
  );

  const getSkipLimit = () => {
    if (
      skipLength !== 0 &&
      ((isActive && data?.activeTotals - skipLength === 1) ||
        (!isActive && data?.inActiveTotals - skipLength === 1))
    ) {
      setSkipLength(skipLength - TEMPLATES_PER_PAGE);
      setCurrentPage(currentPage - 1);
    } else {
      refetch();
    }
  };

  const navigate = useNavigate();

  const tabs = [
    {
      label: 'Active Jobs',
      slug: 'active_jobs',
      count: data?.activeTotals,
      underline: isActive,
    },
    {
      label: 'Inactive Jobs',
      slug: 'inactive_jobs',
      count: data?.inActiveTotals,
      underline: !isActive,
    },
  ];

  useEffect(() => {
    refetch();
  }, [isActive, skipLength, debouncedValue]);

  const searchHandler = ({ target: { value } }) => {
    setSearchTerm(value);
  };

  if (isLoading || data === undefined) {
    return <Loader />;
  } else if (isError) {
    return <h1>Error please wait...!</h1>;
  }

  const handleTabs = name => {
    setSearchTerm('');

    if (name === 'active_jobs') {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
  };
  return (
    <>
      {data?.activeTotals || data?.inActiveTotals ? (
        <>
          <MainWrapper>
            <DisplayHeader>
              <Labelparent>
                {tabs.map(tab => (
                  <Tab
                    key={tab.slug}
                    onClick={() => {
                      setSkipLength(0);
                      setCurrentPage(1);
                      handleTabs(tab.slug);
                    }}>
                    <Label isActive={tab.underline}>
                      <span>{tab.label}</span>
                      {tab.count ? <TotalCount>{tab.count}</TotalCount> : null}
                    </Label>
                    <Border isActive={tab.underline}></Border>
                  </Tab>
                ))}
              </Labelparent>

              <SearchBox>
                {data?.data.length > 0 || (data?.data.length === 0 && searchTerm !== '') ? (
                  <>
                    <Searchinput
                      value={searchTerm}
                      onChange={searchHandler}
                      placeholder="Search JD"
                    />
                    <Searchwrapper>
                      <Search />
                    </Searchwrapper>
                  </>
                ) : (
                  <></>
                )}
                <Button
                  padding="10px 20px"
                  width="200px"
                  clickHandler={() => navigate('/create-job')}>
                  Create JD
                </Button>
              </SearchBox>
            </DisplayHeader>
            <>
              {data?.data.length ? (
                <JobsList jobsList={data?.data} activeTab={isActive} fetchJobs={getSkipLimit} />
              ) : (
                <NoJobsFound
                  imgUrl={searchTerm ? SearchNoResults : NoInactiveJobs}
                  title={
                    searchTerm
                      ? 'Oops..! No Results Found'
                      : `You don’t have any ${isActive ? 'active' : 'inactive'} jobs`
                  }
                  para={
                    searchTerm
                      ? ' Please try another search'
                      : `If you are hiring for a job, click on the `
                  }
                />
              )}
            </>
            {data?.data.length !== 0 &&
              ((isActive && data?.activeTotals > TEMPLATES_PER_PAGE) ||
                (!isActive && data?.inActiveTotals > TEMPLATES_PER_PAGE)) && (
                <Pagination
                  totalRows={isActive ? data?.activeTotals : data?.inActiveTotals}
                  rowsPerPage={TEMPLATES_PER_PAGE}
                  pageChangeHandler={skip => setSkipLength(skip)}
                  setCurrentPage={setCurrentPage}
                  currentPage={currentPage}
                />
              )}
          </MainWrapper>
        </>
      ) : (
        <NoJobs />
      )}
    </>
  );
};

export default DisplayJobs;
