import React from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

import styled from 'styled-components';
import { theme } from '../styles/GlobalStyles';

const MainContainer = styled.div`
  padding: ${({ pd }) => pd};
  margin: 0;
  background-color: ${({ bg }) => bg};
  display: flex;
  // justify-content: center;
  align-items: center;
  flex-flow: column;
  /* height: 93vH */
  min-height: calc(100vh - 7vh);
`;
const Layout = () => {
  const search = useLocation();
  const isPreview = search.pathname === '/jd';
  return (
    <>
      {
        <MainContainer
          bg={isPreview ? theme.white : theme.backgroundColor}
          pd={isPreview ? '0' : '2.25rem 2rem'}>
          <Outlet />
        </MainContainer>
      }
    </>
  );
};
Layout.propTypes = {};
export default Layout;
